import React from "react";
import { COMMON_NAMESPACES, useTranslation } from "../../i18n";
import ErrorPage from "@/shared/error/ErrorPage";
import Link from "next/link";
import LinksProvider from "@/services/http/LinksProvider";

const namespacesRequired = [...COMMON_NAMESPACES, "error-pages"];

const Error = ({ code }) => {
  const { t } = useTranslation(namespacesRequired);

  return (
    <ErrorPage
      title={t(`error-pages:error-${code}.title`)}
      desc={t(`error-pages:error-${code}.desc`)}
      background={`/static/images/errors/${code}.jpg`}
    >
      <Link href={LinksProvider.ROUTES.HOME}>
        <a className="btn__primary">{t(`error-pages:error-${code}.cta`)}</a>
      </Link>
    </ErrorPage>
  );
};

export default Error;
