import React from "react";
import NextErrorComponent from "next/error";
import * as Sentry from "@sentry/nextjs";
import { COMMON_NAMESPACES, withTranslation } from "../i18n";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { default as ErrorComponent } from "@/components/error";

const namespacesRequired = [...COMMON_NAMESPACES, "error-pages"];

const Error = (props) => {
  if (!props.hasGetInitialPropsRun && props.err) {
    // getInitialProps is not called in case of
    // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
    // err via _app.js so it can be captured
    Sentry.captureException(props.err);
    // Flushing is not required in this case as it only happens on the client
  }

  return (
    <React.Fragment>
      <ErrorComponent code={props.statusCode} />
    </React.Fragment>
  );
};

Error.getInitialProps = async (ctx) => {
  const errorInitialProps = await NextErrorComponent.getInitialProps(ctx);

  // Workaround for https://github.com/vercel/next.js/issues/8592, mark when
  // getInitialProps has run
  errorInitialProps.hasGetInitialPropsRun = true;

  // Running on the server, the response object (`res`) is available.
  //
  // Next.js will pass an err on the server if a page's data fetching methods
  // threw or returned a Promise that rejected
  //
  // Running on the client (browser), Next.js will provide an err if:
  //
  //  - a page's `getInitialProps` threw or returned a Promise that rejected
  //  - an exception was thrown somewhere in the React lifecycle (render,
  //    componentDidMount, etc) that was caught by Next.js's React Error
  //    Boundary. Read more about what types of exceptions are caught by Error
  //    Boundaries: https://reactjs.org/docs/error-boundaries.html

  if (ctx && ctx.res && ctx.res.statusCode && ctx.err) {
    Sentry.captureException(ctx.err);

    return {
      ...errorInitialProps,
      statusCode: ctx.res.statusCode,
      namespacesRequired,
    };
  }

  // If this point is reached, getInitialProps was called without any
  // information about what the error might be. This is unexpected and may
  // indicate a bug introduced in Next.js, so record it in Sentry
  Sentry.captureException(
    new Error(`_error.js getInitialProps missing data at path: ${ctx.asPath}`)
  );

  return { ...errorInitialProps, statusCode: ctx.res.statusCode || 500 };
};

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, namespacesRequired)),
    },
  };
}

export default withTranslation(namespacesRequired)(Error);
