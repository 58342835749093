import React from 'react';
import useKonamiCode from "../konami";
import Heading from "../textes/Heading";
import Modal from "../modal/Modal";
import css from './Error.module.scss';

const ErrorPage = (props) => {
  const [konamiCode, setIsKonami] = useKonamiCode();
  return (
    <div className={css.error} style={{backgroundImage: `url('${props.background}')`}}>
      <Heading headerContent={props.title} customClass={css.error__title}/>
      <div className={css.error__action_bloc}>
        <p dangerouslySetInnerHTML={{__html: props.desc}}/>

        {props.children}
      </div>
      {konamiCode && (
        <Modal visible={konamiCode} onHide={() => setIsKonami(false)}>
          <div style={{
            backgroundColor: "#fff",
            padding: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"

          }}>
            <h1 style={{
              fontSize: "100%",
              textAlign: "center",
              color: "#010101",
              textTransform: "uppercase"
            }}>
              <img src={'/static/images/errors/potimg.png'} style={{
                display: "block",
                width: "80vw",
                maxWidth: "600px"
              }}/>
            </h1>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ErrorPage;
